<template>
  <footer class="footer" :class="{'footer-fixed-default': fixedDefault}">
    <div class="container container--footer">
      <div class="footer_mid">
        <div class="footer_mid_bottom">
          <div class="footer_mid_left">
            <p class="copyright">Copyright © {{ new Date().getFullYear() }} Best Novels Inc</p>
          </div>
          <div class="footer_mid_right">
            <div class="foot_mid marb0">
              <ul class="menu footer_navbar">
                <li><router-link class="link" to="/readme-privacy">Privacy Policy</router-link></li>
                <li><router-link class="link" to="/readme-terms">Terms of Use</router-link></li>
<!--                <li><router-link class="link" to="/contact">Contact</router-link></li>-->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  props: {
    bottomFixed: {
      type: Boolean,
    },
    fixedDefault: {
      type: Boolean,
    },
  },
}
</script>

<style scoped>
.footer {
  /*background: #2b3c67;*/
  background: #2A1F66;
  /*position: relative;*/
  color: #fff;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}
@media only screen and (max-width: 767px) {
  .footer {
    position: relative;
  }
}
.footer:before {
  background: url(../../assets/img/footer-bg.png) no-repeat 100% 0;
  width: 100%;
  height: 142px;
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: -20%;
  z-index: -1;
  background-size: 100%;
}
@media only screen and (max-width: 1279px) {
  .footer:before {
    height: 100px;
    top: -14%;
  }
}

.container--footer {
  margin: 0 auto;
  /*width: 1100px;*/
  /*max-width: 100%;*/
  padding: 0 15px;
  /*background: #2b3c67;*/
  text-align: center;
  background: #2a1f66;
}
@media only screen and (max-width: 1025px) {
  .container--footer {
    width: 100%;
  }
}
.container {
  padding: 0 20px;
}
@media (min-width: 769px) and (max-width: 4500px) {
  * {
    margin: 0;
    padding: 0;
  }
}
.footer_mid {
  padding: 40px 0 30px;
  width: 1100px;
  max-width: 100%;
  display: inline-block;
  margin: 0 auto;
}
.footer_mid_left, .footer_mid_right {
  display: inline-block;
  vertical-align: top;
  width: 49%;
}
.footer_mid_left {
  padding-right: 100px;
}
@media only screen and (max-width: 991px) {
  .footer_mid_left {
    padding-right: 50px;
  }
}
p {
  margin: 15px 0;
}
.footer_mid_left p {
  color: #c7c7c7;
  font-size: 13px;
  line-height: 22px;
  margin-bottom: 0;
}
p:first-child {
  margin-top: 0;
}
p:last-child {
  margin-bottom: 0;
}
.footer p.copyright {
  color: #abb0bd;
  font-size: 13px;
  font-weight: 400;
}
@media only screen and (max-width: 991px) {
  .footer p.copyright {
    font-size: 13px;
  }
}
.footer_mid_right .foot_mid {
  width: 32%;
  display: inline-block;
  vertical-align: top;
}
.foot_mid.marb0 {
  width: 100%;
}
ol, ul {
  padding: 0;
  margin: 0 0 15px 30px;
}
.footer ul {
  padding-left: 0;
}
.menu.footer_navbar {
  width: 100%;
}
.footer_mid_right .foot_mid ul {
  list-style: none;
  margin-top: 0;
  margin-left: 0;
}
.menu.footer_navbar li {
  display: inline-block;
  width: 32%;
}
.footer_mid_right .foot_mid ul li {
  margin-bottom: 10px;
}
.link {
  text-decoration: underline;
  color: #959eb3;
  font-size: 14px;
}
@media only screen and (max-width: 767px) {
  .footer_mid_bottom {
    text-align: left;
  }
  .footer_mid_left {
    padding-right: 0;
    width: 100%;
    margin-bottom: 30px;
  }
  .footer_mid_right {
    padding-right: 0;
    width: 100%;
  }
  .footer_mid {
    padding: 20px 0 10px;
  }
}

</style>
