import { render, staticRenderFns } from "./novelPrivacy.vue?vue&type=template&id=2f4dc481&scoped=true&"
var script = {}
import style0 from "../contact/contact1.css?vue&type=style&index=0&id=2f4dc481&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f4dc481",
  null
  
)

export default component.exports