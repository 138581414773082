<template>
  <div id="app" :class="{bg: !fixedDefault}">
    <router-view />
    <footer-link :bottomFixed="bottomFixed" :fixedDefault="fixedDefault"></footer-link>
  </div>
</template>

<script>
import FooterLink from "@/components/footer/contact1-footer.vue";
import DefaultParams from "@/utils/defalut_params";
export default {
  components: {
    FooterLink
  },
  data() {
    return {
      bottomFixed: false,
      fixedDefault: false
    }
  },
  watch:{
    $route(){
      window.scrollTo(0,0);
      this.judgeBottomFixed();
    },
  },
  created() {
    console.log(1);
    this.judgeBottomFixed();
    DefaultParams.init(this.$route.query);
  },
  methods: {
    judgeBottomFixed() {
      const isDailyPage = this.$route.path.includes("daily");
      const isContactPage = this.$route.path.includes("contact");
      this.bottomFixed = isDailyPage;
      this.fixedDefault = isContactPage;
    }
  }
};
</script>
<style scoped>
.bg {
  background: #2A1F66;
}
</style>
