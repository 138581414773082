import { render, staticRenderFns } from "./novelTerms.vue?vue&type=template&id=576bb305&scoped=true&"
var script = {}
import style0 from "../contact/contact1.css?vue&type=style&index=0&id=576bb305&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "576bb305",
  null
  
)

export default component.exports