import Vue from "vue";
import VueRouter from "vue-router";
// import contact1 from "../views/contact/contact1.vue";
// import contact2 from "../views/contact/contact2.vue";
import terms from "../views/privacyAndTerms/novelTerms.vue";
import privacy from "../views/privacyAndTerms/novelPrivacy.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/readme-privacy"
  },
 {
  //   path: "/contact",
  //   name: "contact2",
  //   component: contact2
  // },{
    path: "/readme-privacy",
    name: "privacy",
    component: privacy
  },{
    path: "/readme-terms",
    name: "terms",
    component: terms
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
